import React, { useState, useEffect } from "react";
// prop types
import PropTypes from "prop-types";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Tabs, Row, Col, Button, Spin, Modal, Input, Select } from "antd";
import {
  DownloadOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
// redux
import { useSelector, useDispatch } from "react-redux";
import {
  setPolicyList,
  setErrors,
  fetchProposal,
  saveProposal,
  acceptProposal,
  changeProposal,
  launchIntegrationAverbamais,
  removePolicyFile,
  setNeedSaveProposal,
} from "../../../../../store/slices/proposal/cargo";
// components
import FormField from "../../../../../components/FormField";
import MaskedInput from "../../../../../components/MaskedInput";
import { ProposalForm } from "../../../../../components/Order/OrderTabs/CargoOpenPolicyTabs/tabs/Proposal";
// helpers
import {
  clearError,
  formatMaskInput,
  downloadFile,
  uploadConfig,
  getErrorMessage,
  inputMasks,
  monthFormat,
  dateFormat,
} from "../../../../../helpers";
// contants
import selections from "../../../../../constants/selections";
import { fetchPolicyActionLinks } from "../../../../../store/slices/order";
import moment from "moment";

const { insurerByProduct, policyTypes, registerApplicationTypes } = selections;

const { TabPane } = Tabs;
const { Option } = Select;

const Proposal = ({ code, documentNumber }) => {
  const [tab, setTab] = useState("0");
  const [coverageTab, setCoveragesTab] = useState("0");

  const {
    proposal,
    proposal: { policies },
    errors,
    isLoading,
    needSaveProposal,
  } = useSelector((state) => state.proposal.cargoProposal);
  const {
    policyActionsData: { isTabActionsEnabled },
  } = useSelector((state) => state.order);
  const dispatch = useDispatch();
  const { t: translation } = useTranslation();

  const getPolicyTypeLabel = (policyType) => {
    return selections.policyTypes.filter(({ value }) => value === policyType)[0]
      .label;
  };

  const handleChange = (field, value) => (index) => {
    //dispatch(setNeedSaveProposal(true));

    if (value && value.hasOwnProperty("formattedValue")) {
      value = value.floatValue;
    }

    if (value && value.target) {
      value = value.target.value;
    }

    if (field && field === "firstInstallmentRecurrenceMonth") {
      return dispatch(
        changeProposal({ [field]: moment(value).format(monthFormat) })
      );
    }

    if (field && field === "firstInstallmentDueDate") {
      return dispatch(
        changeProposal({ [field]: moment(value).format(dateFormat) })
      );
    }

    if (
      field === "contactName" ||
      field === "contactEmail" ||
      field === "contactPhoneNumber" ||
      field === "registerApplication" ||
      field === "numberInstallments" ||
      field === "firstInstallmentAmount"
    ) {
      if (field === "contactPhoneNumber") {
        value = formatMaskInput(value);
      }
      dispatch(changeProposal({ [field]: value }));
    } else {
      let newPolicyList = [...policies];
      newPolicyList[index] =
        field === "policyType"
          ? { ...newPolicyList[index], policyType: value, coverages: [] }
          : { ...newPolicyList[index], [field]: value };
      dispatch(setPolicyList(newPolicyList));
    }

    if (field === "coverages") {
      dispatch(setErrors([]));
    } else {
      if (field === "startAt" || field === "finalAt") {
        const arrayErrors = clearError(`policies[${index}].finalAt`, errors);
        dispatch(
          setErrors(clearError(`policies[${index}].startAt`, arrayErrors))
        );
      } else {
        dispatch(setErrors(clearError(`policies[${index}].${field}`, errors)));
      }
    }
  };

  const handleAddPolicy = () => {
    setTab(`${policies.length}`);
    dispatch(setPolicyList([...policies, {}]));
  };

  const handleAddCoverage = (policy) => (index) => {
    const newPolicy = {
      ...policy,
      coverages: policy.coverages ? [...policy.coverages, {}] : [{}],
    };
    let newPolicyList = [...policies];
    newPolicyList[index] = newPolicy;
    dispatch(setPolicyList(newPolicyList));
  };

  const handleDeleteCoverage = (policy, coverageIndex, policyIndex) => {
    setCoveragesTab(`${policy.coverages.length - 2}`);
    let currentCoverages = [...policy.coverages];
    const newPolicy = {
      ...policy,
      coverages: currentCoverages?.filter(
        (_, index) => index != coverageIndex
      ) || [{}],
    };
    let newPolicyList = [];
    policies.map((policy, index) => {
      if (index === policyIndex) {
        newPolicyList.push(newPolicy);
      } else {
        newPolicyList.push(policy);
      }
    });

    dispatch(setPolicyList(newPolicyList));
  };

  const handleAccept = () => {
    return (
      code &&
      dispatch(acceptProposal(code)).then(
        () =>
          documentNumber &&
          dispatch(fetchPolicyActionLinks(documentNumber, code))
      )
    );
  };

  const handleDeletePolicy = (index) => {
    setTab(`${policies.length - 2}`);
    const newPolicyList = [...policies];
    newPolicyList.splice(index, 1);
    dispatch(setPolicyList(newPolicyList));
  };

  const handleSavePolicy = () => {
    return (
      code &&
      dispatch(saveProposal(code)).then(() => {
        dispatch(fetchProposal(code));
        dispatch(setNeedSaveProposal(false));
      })
    );
  };

  const handleIntegration = () => {
    dispatch(launchIntegrationAverbamais(code));
  };

  const handleRemoveProposal = (policyId) => {
    const modal = Modal.confirm({
      title: translation("proposal.removePolicyFileModalTitle"),
      okText: translation("proposal.removePolicyFileModalButton"),
      cancelText: translation("proposal.removePolicyFileModalCancelButton"),
      onOk: () => {
        dispatch(removePolicyFile(code, policyId)).then(() => {
          dispatch(fetchProposal(code));
        });
        modal.destroy();
      },
      onCancel: () => {
        modal.destroy();
      },
    });
  };

  const handleRemovePolicyType = (id) => {
    const modal = Modal.confirm({
      title: translation("proposal.removePolicyTypeModalTitle"),
      okText: translation("proposal.removePolicyTypeModalButton"),
      cancelText: translation("proposal.removePolicyTypeModalCancelButton"),
      onOk: () => {
        handleDeletePolicy(id);
        modal.destroy();
      },
      onCancel: () => {
        modal.destroy();
      },
    });
  };

  const handleRemoveCoverage = (data, coverageIndex, index) => {
    const modal = Modal.confirm({
      title: translation("proposal.removeCoverageModalTitle"),
      okText: translation("proposal.removeCoverageModalButton"),
      cancelText: translation("proposal.removeCoverageModalCancelButton"),
      onOk: () => {
        handleDeleteCoverage(data, coverageIndex, index);
        modal.destroy();
      },
      onCancel: () => {
        modal.destroy();
      },
    });
  };

  const getUploadFileProps = (policyData) => {
    return {
      uploadText: translation("proposal.uploadPolicyFileButton"),
      downloadText: translation("proposal.downloadPolicyFileButton"),
      disabledUpload: !policyData.id,
      hasFile: policyData.file && policyData.file.link,
      uploadProps: {
        ...uploadConfig(),
        showUploadList: false,
        multiple: false,
        action: `${window._env_.API_GATEWAY_URL}/cargo/v1/open-policy/${code}/proposal/${policyData.id}/signed`,
        onSuccess: () => code && dispatch(fetchProposal(code)),
      },
      onRemove: () => handleRemoveProposal(policyData.id),
      onDownload: () =>
        policyData.file &&
        downloadFile(policyData.file.link, `policy-${policyData.id}`),
    };
  };

  useEffect(() => {
    if (code) {
      dispatch(fetchProposal(code));
    }
  }, [dispatch]);

  useEffect(() => {
    if (policies && policies.length === 0) {
      handleAddPolicy();
    }
  }, [policies]);

  useEffect(() => {
    dispatch(setNeedSaveProposal(false));
  }, [dispatch]);

  return (
    <Spin spinning={isLoading}>
      <Col sm={24}>
        <Row
          type="flex"
          justify="space-between"
          className="gx-mt-3 gx-mb-1  gx-pr-3"
        >
          <h5>{translation("proposal.registerResponsible")}</h5>
        </Row>
        <Row type="flex" align="middle" className="gx-mb-10">
          <Col sm={24} md={3} className="gx-p-0">
            <p className="gx-m-0">{translation("proposal.contactNameLabel")}</p>
          </Col>
          <Col sm={24} md={9}>
            <FormField
              className="gx-m-0"
              error={getErrorMessage("contactName", errors)}
            >
              <Input
                className="gx-w-100"
                value={proposal.contactName}
                onChange={(value) => handleChange("contactName", value)(0)}
                placeholder={translation("proposal.contactNamePlaceholder")}
              />
            </FormField>
          </Col>
          <Col sm={24} md={3} className="gx-p-0">
            <p className="gx-m-0">
              {translation("proposal.contactEmailLabel")}
            </p>
          </Col>
          <Col sm={24} md={9}>
            <FormField
              className="gx-m-0"
              error={getErrorMessage("contactEmail", errors)}
            >
              <Input
                className="gx-w-100"
                type="email"
                value={proposal.contactEmail}
                onChange={(value) => handleChange("contactEmail", value)(0)}
                placeholder={translation("proposal.contactEmailPlaceholder")}
              />
            </FormField>
          </Col>
        </Row>
        <Row type="flex" align="middle" className="gx-mb-10">
          <Col sm={24} md={3} className="gx-p-0">
            <p className="gx-m-0">
              {translation("proposal.contactPhoneLabel")}
            </p>
          </Col>
          <Col sm={24} md={9}>
            <FormField
              className="gx-m-0"
              error={getErrorMessage("contactPhoneNumber", errors)}
            >
              <MaskedInput
                className="gx-w-100"
                mask={inputMasks.mobileNumber}
                value={proposal.contactPhoneNumber}
                onChange={(value) =>
                  handleChange("contactPhoneNumber", value)(0)
                }
                placeholder={translation("proposal.contactPhonePlaceholder")}
              />
            </FormField>
          </Col>
          <Col sm={24} md={3} className="gx-p-0">
            <p className="gx-m-0">
              {translation("proposal.registerApplicationLabel")}
            </p>
          </Col>
          <Col sm={24} md={9}>
            <FormField
              className="gx-m-0"
              error={getErrorMessage("registerApplication", errors)}
            >
              <Select
                className="gx-w-100"
                onChange={(value) =>
                  handleChange("registerApplication", value)(0)
                }
                value={proposal.registerApplication}
                placeholder={translation(
                  "proposal.registerApplicationPlaceholder"
                )}
              >
                {registerApplicationTypes.map((item, index) => (
                  <Option key={index} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </FormField>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          style={{ marginTop: "32px", marginBottom: "24px" }}
          className="gx-pr-3"
        >
          <h5 className="gx-m-0 ">{translation("proposal.addPolicyLabel")}</h5>
          <Button
            type="primary"
            shape="circle"
            className="gx-m-0 "
            onClick={handleAddPolicy}
          >
            <PlusOutlined />
          </Button>
        </Row>
        {policies.length && policies.length > 0 ? (
          <Tabs
            tabPosition="top"
            hideAdd
            type="editable-card"
            activeKey={tab}
            onChange={(e) => setTab(e)}
            onEdit={(targetKey) => handleRemovePolicyType(targetKey)}
            className="cargo-open-policy-tabs order-tabs-sm"
            renderTabBar={(tabBarProps, DefaultTabBar) => (
              <DefaultTabBar {...tabBarProps}>
                {(node) => (
                  <div
                    {...node.props}
                    key={node.key}
                    className={`${node.props.className} cargo-open-policy-tabs-tab`}
                  />
                )}
              </DefaultTabBar>
            )}
          >
            {policies.map((policy, index) => (
              <TabPane
                tab={
                  policy.policyType
                    ? `${translation("proposal.tabLabel")} ${getPolicyTypeLabel(
                        policy.policyType
                      )}`
                    : `${translation("proposal.tabLabel")} ${index + 1}`
                }
                key={`${index}`}
              >
                <ProposalForm
                  errors={errors}
                  isLoading={isLoading}
                  policyIndex={index}
                  policyData={policy}
                  proposal={proposal}
                  needSaveProposal={needSaveProposal}
                  uploadProps={getUploadFileProps(policy)}
                  onAccept={handleAccept}
                  onSavePolicy={handleSavePolicy}
                  onChange={(field, value) => handleChange(field, value)(index)}
                  onAddCoverage={(data) => handleAddCoverage(data)(index)}
                  setTab={setCoveragesTab}
                  tab={coverageTab}
                  onDeleteCoverage={(data, coverageIndex) =>
                    handleRemoveCoverage(data, coverageIndex, index)
                  }
                  onLaunchIntegration={handleIntegration}
                  isTabActionsEnabled={isTabActionsEnabled}
                />
              </TabPane>
            ))}
          </Tabs>
        ) : (
          <Row type="flex" justify="center" className="gx-mt-4 gx-mb-30">
            <h5>{translation("proposal.noData")}</h5>
          </Row>
        )}
      </Col>
    </Spin>
  );
};

Proposal.propTypes = {
  code: PropTypes.string,
  documentNumber: PropTypes.string,
};

export default Proposal;
