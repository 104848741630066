import React, { useEffect, useState } from "react";
// prop types
import PropTypes from "prop-types";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Row, Col, Button, Spin, Modal } from "antd";
import moment from "moment";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setErrors,
  setProposal,
  fetchLifeProposal,
  removeLifeProposalFile,
  saveLifeProposal,
  acceptLifeProposal,
} from "../../../../../store/slices/proposal/life";
import { fetchUsers } from "../../../../../store/slices/user";
import { fetchPolicyActionLinks } from "../../../../../store/slices/order";
// components
import { ProposalForm } from "../../../../../components/Order/OrderTabs/LifeTabs/tabs/Proposal";
import { UploadFile } from "../../../../../components";
// helpers
import {
  uploadConfig,
  downloadFile,
  clearError,
  monthFormat,
  dateFormat,
} from "../../../../../helpers";
// constants
import { taxByProduct } from "../../../../../constants";

const Proposal = ({ code, documentNumber }) => {
  const [isProposalSaved, setProposalSaved] = useState(false);

  const {
    user: { userList },
    common: { isLoading },
    proposal: {
      lifeProposal: { proposal, errors },
    },
    order: {
      policyActionsData: { isTabActionsEnabled },
    },
  } = useSelector((state) => state);

  const uploadProposalFileUrl = code
    ? `${window._env_.API_GATEWAY_URL}/life/v2/${code}/proposal/upload`
    : "";

  const { t: translation } = useTranslation();
  const dispatch = useDispatch();

  const getSalesRepresentative = () =>
    userList
      .filter((item) => item.salesProfile)
      .sort((a, b) => a.name.localeCompare(b.name));

  const getPreSalesRepresentative = () =>
    userList
      .filter((item) => item.preSalesProfile)
      .sort((a, b) => a.name.localeCompare(b.name));

  const showRemoveFileModal = () => {
    const modal = Modal.confirm({
      maskClosable: true,
      title: translation("life.proposal.removeFileQuestion"),
      onCancel: () => modal.destroy(),
      onOk: () => {
        dispatch(removeLifeProposalFile(code)).then(() =>
          dispatch(fetchLifeProposal(code))
        );
        modal.destroy();
      },
    });
  };

  const formatChangedValue = (field, value) => {
    if (value && (value.formattedValue || value.formattedValue === "")) {
      return value.floatValue;
    }

    if (field === "employeesAmount" || field === "movementLimitDay") {
      return Number(value.target.value);
    }

    if (field === "firstInstallmentRecurrenceMonth") {
      return moment(value).format(monthFormat);
    }

    if (field === "firstInstallmentDueDate") {
      return moment(value).format(dateFormat);
    }

    if (value && value.target) {
      return value.target.value;
    }

    return value;
  };

  const handleChange = (field, value) => {
    value = formatChangedValue(field, value);
    dispatch(setProposal({ ...proposal, [field]: value }));
    dispatch(setErrors(clearError(field, errors)));
  };

  const handleUploadFileSuccess = () => {
    dispatch(fetchLifeProposal(code)).then(() =>
      dispatch(fetchLifeProposal(code))
    );
  };

  const handleRemoveFile = () => {
    showRemoveFileModal(code);
  };

  const handleAcceptProposal = () => {
    dispatch(acceptLifeProposal(code))
      .then(() => dispatch(fetchLifeProposal(code)))
      .then(
        () =>
          documentNumber &&
          dispatch(fetchPolicyActionLinks(documentNumber, code))
      );
  };
  const handleSaveProposal = () => {
    dispatch(saveLifeProposal(code)).then(() => {
      dispatch(fetchLifeProposal(code));
      setProposalSaved(true);
    });
  };

  useEffect(() => {
    if (code) {
      dispatch(fetchLifeProposal(code));
    }
    dispatch(fetchUsers());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setProposal({ iof: taxByProduct.LIFE }));
    };
  }, [dispatch]);

  return (
    <Spin spinning={isLoading}>
      <Col sm={24}>
        <ProposalForm
          errors={errors}
          proposal={proposal}
          sales={getSalesRepresentative()}
          preSales={getPreSalesRepresentative()}
          onChange={handleChange}
        />
        <Row
          type="flex"
          align="middle"
          style={{ gap: "16px", marginBottom: "80px" }}
        >
          <Col sm={24} md={6} className="gx-p-0">
            <UploadFile
              uploadText={translation("life.proposal.attachProposal")}
              downloadText={translation("life.proposal.downloadProposal")}
              hasFile={proposal.file}
              disabledUpload={!isProposalSaved}
              uploadProps={{
                ...uploadConfig(null, null, null, "PUT"),
                showUploadList: false,
                multiple: false,
                action: uploadProposalFileUrl,
                onSuccess: handleUploadFileSuccess,
              }}
              onRemove={handleRemoveFile}
              onDownload={() =>
                downloadFile(proposal.file?.url, `proposal-${code}`)
              }
            />
          </Col>
        </Row>
        {isTabActionsEnabled && (
          <Row type="flex" justify="end" className="gx-mt-3">
            <Button
              ghost
              type="primary"
              className="gx-mr-2"
              onClick={handleAcceptProposal}
              disabled={!isProposalSaved}
            >
              {translation("life.proposal.acceptButton")}
            </Button>
            <Button type="primary" onClick={handleSaveProposal}>
              {translation("life.proposal.saveButton")}
            </Button>
          </Row>
        )}
      </Col>
    </Spin>
  );
};

Proposal.propTypes = {
  code: PropTypes.string,
  documentNumber: PropTypes.string,
};

export default Proposal;
