import React, { useState } from "react";
// prop types
import PropTypes from "prop-types";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Row, Col, Select, DatePicker, Tabs, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
// components
import FormField from "../../../../../FormField";
import CurrencyInput from "../../../../../CurrencyInput";
import CoverageForm from "./CoverageForm";
import UploadFile from "../../../../../UploadFile";
// helpers
import {
  dateFormat,
  frontEndDate,
  getErrorMessage,
} from "../../../../../../helpers";
import moment from "moment";
// constants
import selections from "../../../../../../constants/selections";
import { InvoiceForm } from "./";

const { insurerByProduct, policyTypes } = selections;
const { Option } = Select;
const { TabPane } = Tabs;

const ProposalForm = ({
  policyIndex,
  isLoading,
  policyData = {},
  proposal = {},
  errors = [],
  needSaveProposal,
  uploadProps = {},
  onLaunchIntegration,
  onAccept,
  onSavePolicy,
  onChange,
  onAddCoverage,
  onDeleteCoverage,
  isTabActionsEnabled,
  setTab,
  tab = "0",
}) => {
  const { t: translation } = useTranslation();
  const [disableAccept, setDisableAccept] = useState(true);

  const handleIntegration = () => {
    return onLaunchIntegration && onLaunchIntegration();
  };

  const handleAccept = () => {
    return onAccept && onAccept(policyData);
  };

  const handleSavePolicy = () => {
    return onSavePolicy && onSavePolicy(policyData);
  };

  const handleDisableAccept = () => {
    if (
      proposal.contactName &&
      proposal.contactPhoneNumber &&
      proposal.contactEmail &&
      proposal.firstInstallmentRecurrenceMonth &&
      proposal.firstInstallmentDueDate &&
      proposal.numberInstallments &&
      proposal.firstInstallmentAmount
    ) {
      return setDisableAccept(false);
    }
    return setDisableAccept(true);
  };

  const handleChange = (field, value) => {
    handleDisableAccept();
    return onChange && onChange(field, value);
  };

  const handleAddCoverage = () => {
    if (onAddCoverage) {
      onAddCoverage(policyData);
      return policyData.coverages
        ? setTab(`${policyData.coverages.length}`)
        : null;
    }
  };

  const handleDeleteCoverage = (index) => {
    if (onDeleteCoverage) {
      onDeleteCoverage(policyData, index);
    }
  };

  const handleChangeCoverage = (field, value) => (index) => {
    if (value && value.hasOwnProperty("formattedValue")) {
      value = value.floatValue;
    }

    if (value && value.target) {
      if (field === "feeFreeCoverage") {
        value = value.target.checked;
      } else {
        value = value.target.value;
      }
    }

    let newCoverageList = [...policyData.coverages];
    if (field === "feeFreeCoverage" && value) {
      newCoverageList[index] = {
        ...newCoverageList[index],
        rate: 0,
        maximumGuaranteeLimit: 0,
        franchise: 0,
      };
    } else {
      newCoverageList[index] = { ...newCoverageList[index], [field]: value };
    }
    handleChange("coverages", newCoverageList);
  };

  return (
    <>
      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={3} className="gx-p-0">
          <p className="gx-m-0 gx-text-left">
            {translation("proposal.insurerLabel")}
          </p>
        </Col>
        <Col sm={24} md={9}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage(`policies[${policyIndex}].insurer`, errors)}
          >
            <Select
              className="gx-w-100"
              value={policyData.insurer}
              placeholder={translation("proposal.insurerPlaceholder")}
              onChange={(value) => handleChange("insurer", value)}
            >
              {insurerByProduct.CARGO_OPEN_POLICY.sort((a, b) =>
                a.label.localeCompare(b.label)
              ).map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </FormField>
        </Col>

        <Col sm={24} md={3} className="gx-p-0">
          <label className="gx-m-0 gx-text-left">
            {translation("proposal.policyTypeLabel")}
          </label>
        </Col>
        <Col sm={24} md={9}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage(
              `policies[${policyIndex}].policyType`,
              errors
            )}
          >
            <Select
              className="gx-w-100"
              id="policyTypeLabel"
              value={policyData.policyType}
              placeholder={translation("proposal.policyTypePlaceholder")}
              onChange={(value) => handleChange("policyType", value)}
            >
              {policyTypes.map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </FormField>
        </Col>
      </Row>

      {policyData.policyType === "TN" && (
        <Row type="flex" align="middle" className="gx-mb-10">
          <Col sm={24} md={3} className="gx-p-0">
            <p className="gx-m-0 gx-text-left">
              {translation("proposal.coverageTypeLabel")}
            </p>
          </Col>
          <Col sm={24} md={9}>
            <FormField
              className="gx-m-0"
              error={getErrorMessage(
                `policies[${policyIndex}].coverageType`,
                errors
              )}
            >
              <Select
                className="gx-w-100"
                value={policyData.coverageType}
                placeholder={translation("proposal.coverageTypePlaceholder")}
                onChange={(value) => handleChange("coverageType", value)}
              >
                {selections.coverageTypeTN.map((item, index) => (
                  <Option key={index} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </FormField>
          </Col>
        </Row>
      )}

      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={3} className="gx-p-0">
          <p className="gx-m-0 gx-text-left">
            {translation("proposal.termStartAtLabel")}
          </p>
        </Col>
        <Col sm={24} md={9}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage(`policies[${policyIndex}].startAt`, errors)}
          >
            <DatePicker
              className="gx-w-100"
              allowClear={false}
              format={frontEndDate}
              value={
                policyData.startAt
                  ? moment(policyData.startAt, dateFormat)
                  : undefined
              }
              onChange={(value) => handleChange("startAt", value)}
              placeholder={translation("proposal.termStartAtPlaceholder")}
            />
          </FormField>
        </Col>
        <Col sm={24} md={3} className="gx-p-0">
          <p className="gx-m-0 gx-text-left">
            {translation("proposal.termFinalAtLabel")}
          </p>
        </Col>
        <Col sm={24} md={9}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage(`policies[${policyIndex}].finalAt`, errors)}
          >
            <DatePicker
              className="gx-w-100"
              allowClear={false}
              format={frontEndDate}
              value={
                policyData.finalAt
                  ? moment(policyData.finalAt, dateFormat)
                  : undefined
              }
              onChange={(value) => handleChange("finalAt", value)}
              placeholder={translation("proposal.termStartAtPlaceholder")}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={3} className="gx-p-0">
          <p className="gx-m-0 gx-text-left">
            {translation("proposal.netPremiumLabel")}
          </p>
        </Col>
        <Col sm={24} md={9}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage(
              `policies[${policyIndex}].minimumPremium`,
              errors
            )}
          >
            <CurrencyInput
              className="gx-w-100"
              value={policyData.minimumPremium}
              onChange={(value) => handleChange("minimumPremium", value)}
              placeholder={translation("proposal.netPremiumPlaceholder")}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={3} className="gx-p-0">
          <p className="gx-m-0 gx-text-left">
            {translation("proposal.limitLabel")}
          </p>
        </Col>
        <Col sm={24} md={9}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage(
              `policies[${policyIndex}].maximumGuaranteeLimit`,
              errors
            )}
          >
            <CurrencyInput
              className="gx-w-100"
              value={policyData.maximumGuaranteeLimit}
              onChange={(value) => handleChange("maximumGuaranteeLimit", value)}
              placeholder={translation("proposal.limitPlaceholder")}
            />
          </FormField>
        </Col>
        <Col sm={24} md={3} className="gx-p-0">
          <p className="gx-m-0 gx-text-left">
            {translation("proposal.rateLabel")}
          </p>
        </Col>
        <Col sm={24} md={9}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage(`policies[${policyIndex}].rate`, errors)}
          >
            <CurrencyInput
              suffix="%"
              prefix=""
              decimalScale={3}
              className="gx-w-100"
              value={policyData.rate}
              onChange={(value) => handleChange("rate", value)}
              placeholder={translation("proposal.ratePlaceholder")}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mb-10">
        {policyData.policyType === "RCTRC" && (
          <>
            <Col sm={24} md={3} className="gx-p-0">
              <p className="gx-m-0 gx-text-left">
                {translation("proposal.discountLabel")}
              </p>
            </Col>
            <Col sm={24} md={9}>
              <FormField
                className="gx-m-0"
                error={getErrorMessage(
                  `policies[${policyIndex}].discount`,
                  errors
                )}
              >
                <CurrencyInput
                  suffix="%"
                  prefix=""
                  className="gx-w-100"
                  value={policyData.discount}
                  onChange={(value) => handleChange("discount", value)}
                  placeholder={translation("proposal.discountPlaceholder")}
                />
              </FormField>
            </Col>
          </>
        )}
      </Row>
      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={3} className="gx-p-0">
          <p className="gx-m-0 gx-text-left">
            {translation("proposal.commissionLabel")}
          </p>
        </Col>
        <Col sm={24} md={9}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage(
              `policies[${policyIndex}].commission`,
              errors
            )}
          >
            <CurrencyInput
              suffix="%"
              prefix=""
              className="gx-w-100"
              value={policyData.commission}
              onChange={(value) => handleChange("commission", value)}
              placeholder={translation("proposal.commissionPlaceholder")}
            />
          </FormField>
        </Col>
      </Row>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        style={{ marginTop: "40px", marginBottom: "24px" }}
        className="gx-pr-3"
      >
        <h5 className="gx-m-0">{translation("proposal.addCoverageButton")}</h5>
        <Button
          type="primary"
          shape="circle"
          className="gx-m-0 "
          onClick={handleAddCoverage}
        >
          <PlusOutlined />
        </Button>
      </Row>

      {policyData.coverages && policyData.coverages.length > 0 && (
        <Tabs
          hideAdd
          tabPosition="top"
          type="editable-card"
          activeKey={tab}
          onChange={(e) => setTab(e)}
          onEdit={(targetKey) => handleDeleteCoverage(targetKey)}
          className="gx-mb-30 cargo-open-policy-tabs order-tabs-sm"
          renderTabBar={(tabBarProps, DefaultTabBar) => (
            <DefaultTabBar {...tabBarProps}>
              {(node) => (
                <div
                  {...node.props}
                  key={node.key}
                  className={`${node.props.className} cargo-open-policy-tabs-tab`}
                />
              )}
            </DefaultTabBar>
          )}
        >
          {policyData.coverages.map((item, index) => (
            <TabPane
              tab={`${translation("proposal.coverageTabLabel")} ${index + 1}`}
              key={`${index}`}
            >
              <CoverageForm
                policyIndex={policyIndex}
                coverageIndex={index}
                errors={errors}
                coverageData={item}
                policyType={policyData.policyType}
                onChange={(field, value) =>
                  handleChangeCoverage(field, value)(index)
                }
              />
            </TabPane>
          ))}
        </Tabs>
      )}

      <InvoiceForm data={proposal} onChange={handleChange} errors={errors} />

      <Row type="flex" align="middle" className="gx-mb-10 ">
        <Col sm={24} md={12} className="gx-p-0">
          <UploadFile
            {...uploadProps}
            downloadButtonProps={{
              className: "gx-w-100",
            }}
          />
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mb-30">
        <Button
          ghost
          type="primary"
          className="gx-mb-0"
          loading={isLoading}
          onClick={handleIntegration}
        >
          {translation("proposal.integrationButton")}
        </Button>
      </Row>
      {isTabActionsEnabled && (
        <Row
          type="flex"
          align="middle"
          justify="end"
          className="gx-mb-30 gx-px-3"
        >
          <Button
            ghost
            type="primary"
            className="gx-mb-0"
            loading={isLoading}
            onClick={handleAccept}
            disabled={disableAccept}
          >
            {translation("proposal.acceptProposalButton")}
          </Button>
          <Button
            type="primary"
            className="gx-mb-0"
            loading={isLoading}
            onClick={handleSavePolicy}
          >
            {translation("proposal.savePolicyButton")}
          </Button>
        </Row>
      )}
    </>
  );
};

ProposalForm.propTypes = {
  policyIndex: PropTypes.number,
  policyData: PropTypes.object,
  proposal: PropTypes.object,
  errors: PropTypes.array,
  needSaveProposal: PropTypes.bool,
  coverageTypes: PropTypes.array,
  uploadProps: PropTypes.object,
  onAccept: PropTypes.func,
  onDeletePolicy: PropTypes.func,
  onSavePolicy: PropTypes.func,
  onAddCoverage: PropTypes.func,
  onDeleteCoverage: PropTypes.func,
};

export default ProposalForm;
