import React from "react";
// prop types
import PropTypes from "prop-types";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Row, Col, Select, DatePicker } from "antd";
// components
import FormField from "../../../../../FormField";
import CurrencyInput from "../../../../../CurrencyInput";
import LngInput from "../../../../../LngComponents/LngInput";
// helpers
import {
  dateFormat,
  frontEndDate,
  getErrorMessage,
} from "../../../../../../helpers";
import moment from "moment";
// constants
import selections from "../../../../../../constants/selections";
import InvoiceForm from "./InvoiceForm";

const { insurerByProduct, capitalTypes, bussinessPartners } = selections;
const partners = Object.entries(bussinessPartners);

const { Option } = Select;

const ProposalForm = ({
  proposal = {},
  errors = [],
  sales = [],
  preSales = [],
  onChange,
}) => {
  const { t: translation } = useTranslation();

  const handleChange = (field, value) => onChange && onChange(field, value);

  return (
    <>
      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={4} className="gx-p-0">
          <p className="gx-m-0">
            {translation("life.proposal.seguradoraLabel")}
          </p>
        </Col>
        <Col sm={24} md={20}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("insurer", errors)}
          >
            <Select
              className="gx-w-100"
              value={proposal.insurer}
              placeholder={translation("life.proposal.seguradoraPlaceholder")}
              onChange={(value) => handleChange("insurer", value)}
            >
              {insurerByProduct.LIFE.sort((a, b) =>
                a.label.localeCompare(b.label)
              ).map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={4} className="gx-p-0">
          <p className="gx-m-0">
            {translation("life.proposal.capitalTypeLabel")}
          </p>
        </Col>
        <Col sm={24} md={20}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("capitalType", errors)}
          >
            <Select
              className="gx-w-100"
              value={proposal.capitalType}
              placeholder={translation("life.proposal.capitalTypePlaceholder")}
              onChange={(value) => handleChange("capitalType", value)}
            >
              {capitalTypes.map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={4} className="gx-p-0">
          <p className="gx-m-0">
            {translation("life.proposal.employeesAmountLabel")}
          </p>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("employeesAmount", errors)}
          >
            <LngInput
              type="number"
              className="gx-w-100"
              value={proposal.employeesAmount}
              onChange={(value) => handleChange("employeesAmount", value)}
              placeholder={translation(
                "life.proposal.employeesAmountPlaceholder"
              )}
            />
          </FormField>
        </Col>
        <Col sm={24} md={4} className="gx-p-0">
          <p className="gx-m-0">
            {translation("life.proposal.movementLimitDayLabel")}
          </p>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("movementLimitDay", errors)}
          >
            <LngInput
              type="number"
              className="gx-w-100"
              value={proposal.movementLimitDay}
              onChange={(value) => handleChange("movementLimitDay", value)}
              placeholder={translation(
                "life.proposal.movementLimitDayPlaceholder"
              )}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={4} className="gx-p-0">
          <p className="gx-m-0">{translation("life.proposal.rateLabel")}</p>
        </Col>
        <Col sm={24} md={8}>
          <FormField className="gx-m-0" error={getErrorMessage("rate", errors)}>
            <CurrencyInput
              suffix="%"
              prefix=""
              className="gx-w-100"
              value={proposal.rate}
              onChange={(value) => handleChange("rate", value)}
              placeholder={translation("life.proposal.ratePlaceholder")}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={4} className="gx-p-0">
          <p className="gx-m-0">{translation("life.proposal.startAtLabel")}</p>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("startAt", errors)}
          >
            <DatePicker
              className="gx-w-100"
              allowClear={false}
              format={frontEndDate}
              value={
                proposal.startAt
                  ? moment(proposal.startAt, dateFormat)
                  : undefined
              }
              onChange={(value) => handleChange("startAt", value)}
            />
          </FormField>
        </Col>
        <Col sm={24} md={4} className="gx-p-0">
          <p className="gx-m-0">{translation("life.proposal.finalAtLabel")}</p>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("finalAt", errors)}
          >
            <DatePicker
              className="gx-w-100"
              allowClear={false}
              format={frontEndDate}
              value={
                proposal.finalAt
                  ? moment(proposal.finalAt, dateFormat)
                  : undefined
              }
              onChange={(value) => handleChange("finalAt", value)}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={4} className="gx-p-0">
          <p className="gx-m-0">
            {translation("life.proposal.minimumPremiumLabel")}
          </p>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("minimumPremium", errors)}
          >
            <CurrencyInput
              className="gx-w-100"
              value={proposal.minimumPremium}
              onChange={(value) => handleChange("minimumPremium", value)}
              placeholder={translation(
                "life.proposal.minimumPremiumPlaceholder"
              )}
            />
          </FormField>
        </Col>
        <Col sm={24} md={4} className="gx-p-0">
          <p className="gx-m-0">{translation("life.proposal.iofLabel")}</p>
        </Col>
        <Col sm={24} md={8}>
          <FormField className="gx-m-0" error={getErrorMessage("iof", errors)}>
            <CurrencyInput
              readOnly
              suffix="%"
              prefix=""
              className="gx-w-100"
              value={proposal.iof}
              onChange={(value) => handleChange("iof", value)}
              placeholder={translation("life.proposal.iofPlaceholder")}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={4} className="gx-p-0">
          <p className="gx-m-0">
            {translation("life.proposal.commissionLabel")}
          </p>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("commission", errors)}
          >
            <CurrencyInput
              suffix="%"
              prefix=""
              className="gx-w-100"
              value={proposal.commission}
              onChange={(value) => handleChange("commission", value)}
              placeholder={translation("life.proposal.commissionPlaceholder")}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={4} className="gx-p-0">
          <p className="gx-m-0">
            {translation("life.proposal.preSalesRepresentativeLabel")}
          </p>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("preSalesRepresentativeId", errors)}
          >
            <Select
              className="gx-w-100"
              value={proposal.preSalesRepresentativeId}
              placeholder={translation(
                "life.proposal.preSalesRepresentativePlaceholder"
              )}
              onChange={(value) =>
                handleChange("preSalesRepresentativeId", value)
              }
            >
              {preSales.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </FormField>
        </Col>
        <Col sm={24} md={4} className="gx-p-0">
          <p className="gx-m-0">
            {translation("life.proposal.salesRepresentativeLabel")}
          </p>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("salesRepresentativeId", errors)}
          >
            <Select
              className="gx-w-100"
              value={proposal.salesRepresentativeId}
              placeholder={translation(
                "life.proposal.salesRepresentativePlaceholder"
              )}
              onChange={(value) => handleChange("salesRepresentativeId", value)}
            >
              {sales.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={4} className="gx-p-0">
          <p className="gx-m-0">{translation("life.proposal.partnerLabel")}</p>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("partner", errors)}
          >
            <Select
              className="gx-w-100"
              value={proposal.partner}
              placeholder={translation("life.proposal.partnerPlaceholder")}
              onChange={(value) => handleChange("partner", value)}
            >
              {partners
                .sort((a, b) => a[1].label.localeCompare(b[1].label))
                .map((item, index) => (
                  <Option key={index} value={item[0]}>
                    {item[1].label}
                  </Option>
                ))}
            </Select>
          </FormField>
        </Col>
        <Col sm={24} md={4} className="gx-p-0">
          <p className="gx-m-0">
            {translation("life.proposal.commissionPartnerLabel")}
          </p>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("commissionPartner", errors)}
          >
            <CurrencyInput
              suffix="%"
              prefix=""
              className="gx-w-100"
              value={proposal.commissionPartner}
              onChange={(value) => handleChange("commissionPartner", value)}
              placeholder={translation(
                "life.proposal.commissionPartnerPlaceholder"
              )}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={9} className="gx-w-100 gx-p-0">
          <InvoiceForm
            data={proposal}
            onChange={handleChange}
            errors={errors}
          />
        </Col>
      </Row>
    </>
  );
};

ProposalForm.propTypes = {
  proposal: PropTypes.object,
  errors: PropTypes.array,
  sales: PropTypes.array,
  preSales: PropTypes.array,
  handleChange: PropTypes.func,
};

export default ProposalForm;
