import React from "react";
import { Col, DatePicker, Row } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import FormField from "../../../../../FormField";
import {
  dateFormat,
  frontEndDate,
  frontEndMonth,
  getErrorMessage,
  monthFormat,
} from "../../../../../../helpers";
import CurrencyInput from "../../../../../CurrencyInput";
import NumberInput from "../../../../../Inputs/NumberInput";

const InvoiceForm = ({ data = {}, onChange, errors = [] }) => {
  const { t: translation } = useTranslation();

  const handleChange = (field, event) => onChange && onChange(field, event);

  return (
    <>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        style={{ marginTop: "40px", marginBottom: "24px" }}
        className="gx-pr-3"
      >
        <h5 className="gx-m-0">
          {translation("proposal.surety.invoicesData.title")}
        </h5>
      </Row>
      <div style={{ marginBottom: "40px" }}>
        <Row type="flex" align="middle" className="gx-mb-10">
          <Col sm={24} md={4} className="gx-p-0">
            <span className="gx-m-0 ">
              {translation(
                "proposal.surety.invoicesData.recurrenceMonth.label"
              )}
            </span>
          </Col>
          <Col sm={24} md={8}>
            <FormField
              className="gx-m-0"
              error={getErrorMessage(`firstInstallmentRecurrenceMonth`, errors)}
            >
              <DatePicker.MonthPicker
                className="gx-w-100"
                onChange={(event) =>
                  handleChange("firstInstallmentRecurrenceMonth", event)
                }
                allowClear={false}
                format={frontEndMonth}
                value={
                  data?.firstInstallmentRecurrenceMonth
                    ? moment(data?.firstInstallmentRecurrenceMonth, monthFormat)
                    : undefined
                }
              />
            </FormField>
          </Col>
          <Col sm={24} md={4} className="gx-p-0">
            <span className="gx-m-0 ">
              {translation("proposal.surety.invoicesData.dueDate.label")}
            </span>
          </Col>
          <Col sm={24} md={8}>
            <FormField
              className="gx-m-0"
              error={getErrorMessage(`firstInstallmentDueDate`, errors)}
            >
              <DatePicker
                className="gx-w-100"
                allowClear={false}
                format={frontEndDate}
                value={
                  data.firstInstallmentDueDate
                    ? moment(data.firstInstallmentDueDate, dateFormat)
                    : undefined
                }
                onChange={(value) =>
                  handleChange("firstInstallmentDueDate", value)
                }
              />
            </FormField>
          </Col>
        </Row>
        <Row type="flex" align="middle" className="gx-mb-10">
          <Col sm={24} md={4} className="gx-p-0">
            <span className="gx-m-0 ">
              {translation("proposal.surety.invoicesData.installment.label")}
            </span>
          </Col>
          <Col sm={24} md={8}>
            <FormField
              className="gx-m-0"
              error={getErrorMessage(`numberInstallments`, errors)}
            >
              <NumberInput
                className="gx-w-100"
                value={data.numberInstallments}
                onChange={(value) => handleChange("numberInstallments", value)}
              />
            </FormField>
          </Col>
          <Col sm={24} md={4} className="gx-p-0">
            <span className="gx-m-0 ">
              {translation("proposal.surety.invoicesData.amount.label")}
            </span>
          </Col>
          <Col sm={24} md={8}>
            <FormField
              className="gx-m-0"
              error={getErrorMessage(`firstInstallmentAmount`, errors)}
            >
              <CurrencyInput
                className="gx-w-100"
                value={data.firstInstallmentAmount}
                onChange={(value) =>
                  handleChange("firstInstallmentAmount", value)
                }
                placeholder={translation(
                  "proposal.surety.installmentAmountPlaceholder"
                )}
              />
            </FormField>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default InvoiceForm;
